import React from 'react'
import {withRouter} from 'react-router'
import ReactDropzone from 'react-dropzone';
import $ from 'jquery'

class Window extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      files: [],
      dragOver: false,
      isUploading: false
    }
  }

  handleChangeField(e) {
    this.uploadFiles(this.inputElement.files)
  }

  handleFilesDropClick() {
    this.inputElement.click()
  }

  handleDragOver() {
    this.setState({
      dragOver: true
    })
  }

  handleDragLeave(files) {
    this.setState({
      dragOver: false
    })
  }

  onDrop(files) {
    this.uploadFiles(files)
    this.setState({
      dragOver: false
    })
  }

  async uploadFiles(files) {
    this.setState({
      isUploading: true
    })
    var fromData = new FormData()
    for(var i = 0; i < files.length; i++) {
      var fromData = new FormData()
      fromData.append('files[0]', files[i])
      var response = await fetch('https://fp-eauthor.fp-server.com/cms/api/cockpit/addAssets?token=1d40fd932ae1f0b9a197e1e7f2cdac', {
        method: 'POST',
        body: fromData
      }).then(res => res.json())
      var response2 = await fetch(
        'https://fp-eauthor.fp-server.com/cms/api/collections/save/attachments',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            data: {
              title: response.uploaded[0],
              file: response.assets[0]
            }
          })
        }
      )
    }
    this.setState({
      isUploading: false
    })
    this.props.onCloseModal()
  }

  render() {
    return(
      <div className="modal-window">
        <div className="headline">Datei hochladen</div>
        <div className="main">
          <ReactDropzone
            onDragOver={this.handleDragOver.bind(this)}
            onDragLeave={this.handleDragLeave.bind(this)}
            onDrop={this.onDrop.bind(this)}
          >
            {({getRootProps}) => (
              <div
                {...getRootProps()}
              >
                <div
                  className={'files-drop' + (this.state.dragOver ? ' drag-over' : '') + (this.state.isUploading ? ' uploading' : '')}
                  onClick={this.handleFilesDropClick.bind(this)}
                >
                  <div className="text">
                    Dateien hier ablegen<br />
                    oder hier klicken um dateien auszuwählen
                  </div>
                  <div className="loading-bar"></div>
                  <form
                    id="upload-form"
                    className="form image-upload"
                    ref={input => this.form = input}
                    method="post"
                    encType="multipart/form-data"
                  >
                    <input
                      ref={input => this.inputElement = input}
                      type="file"
                      name="files[]"
                      multiple
                      onChange={this.handleChangeField.bind(this)}
                    />
                  </form>
                </div>
              </div>
            )}
          </ReactDropzone>
        </div>
        <button
          className="close-modal"
          onClick={this.props.onCloseModal}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25.223" height="25.223" viewBox="0 0 25.223 25.223">
            <path d="M0,10.165H7.671v7.671h2.4V10.165h7.765v-2.4H10.071V0h-2.4V7.765H0Z" transform="translate(12.611 0) rotate(45)" />
          </svg>
        </button>
      </div>
    )
  }
}

export default withRouter(Window)
