import React from 'react'

const CONFIG_MODULE = {
  settings: {
    sections: [
      {
        headline: 'Überschrift',
        fields: [
          {
            type: 'switch',
            name: 'headline_show',
            label: 'Anzeigen',
            defaultValue: true
          },
          {
            type: 'textarea',
            name: 'headline',
            label: 'Headline'
          }
        ]
      }
    ]
  }
}

export default CONFIG_MODULE
